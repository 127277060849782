import { createContext, ReactNode, useContext } from "react"
import { ParsedEnv } from "~/types/global"

const context = createContext<Partial<ParsedEnv>>({})

interface Props {
  env: ParsedEnv
  children: ReactNode
}

export const EnvProvider = ({ children, env }: Props) => {
  return <context.Provider value={env}>{children}</context.Provider>
}

export const useEnv = () => useContext(context)
